import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Product from '../src/components/product'; 
import User from '../src/components/login'; 

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/product" element={<Product />} />
          <Route path="/" element={<User />} />
         
        </Routes>
      </div>
    </Router>
  );
}

export default App;
