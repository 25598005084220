import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { FiBox, FiTag, FiDollarSign, FiTool, FiPackage, FiCalendar, FiInfo } from 'react-icons/fi';
import axios from 'axios';
import Select from 'react-select';
import config from '../config';
import Navbar from "./navbar";

const Product = () => {
  const initialProductDetails = {
    ItemCode: '',
    ProductName: '',
    ReceiptNo: '',
    ReceiptDate: '',
    ItemDiscount: '',
    Quantity: '',
    UserDept: '',
    TemporaryLocation: '',
    PurchaseOrder: '',
    SupplierName: ''
  };

  const [productDetails, setProductDetails] = useState({ ...initialProductDetails });
  const [submitted, setSubmitted] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [itemCodes, setItemCodes] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    fetchItemCodes();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductDetails({
      ...productDetails,
      [name]: value,
    });
  };

  const fetchItemCodes = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/Product/itemcodes`);
      const { itemCodes } = response.data;
      const options = itemCodes.map(itemCode => ({ value: itemCode, label: itemCode }));
      setItemCodes(options);
    } catch (error) {
      console.error('Error fetching ItemCodes:', error);
      // Handle error fetching item codes
    }
  };

  const handleItemChange = async (selectedItem) => {
    setSelectedItem(selectedItem);
    try {
      const response = await axios.get(`${config.apiUrl}/Product/productname/${selectedItem.value}`);
      const { ProductName } = response.data;
      setProductDetails((prevDetails) => ({
        ...prevDetails,
        ItemCode: selectedItem.value,
        ProductName: ProductName,
      }));
    } catch (error) {
      console.error('Error fetching ProductName:', error);
      // Handle error fetching product name
    }
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.values(productDetails).some(detail => detail === '')) {
      // alert('Please fill in all the details.');
      setShowQRCode(false);
      setSubmitted(true); 
      return;
    }

    axios.post(`${config.apiUrl}/Product/add-product`, productDetails)
      .then(response => {
        console.log(response.data);
        setShowQRCode(true);
        setSubmitted(true);
      })
      .catch(error => {
        console.error("There was an error adding the product!", error);
      });
  };

  const qrCodeValue = JSON.stringify(productDetails);

  const icons = {
    ItemCode: <FiTag className="inline-block text-lg text-pink-700 mr-2" />,
    ProductName: <FiBox className="inline-block text-lg text-pink-700 mr-2" />,
    ReceiptNo: <FiPackage className="inline-block text-lg text-pink-700 mr-2" />,
    ReceiptDate: <FiCalendar className="inline-block text-lg text-pink-700 mr-2" />,
    ItemDiscount: <FiDollarSign className="inline-block text-lg text-pink-700 mr-2" />,
    Quantity: <FiTool className="inline-block text-lg text-pink-700 mr-2" />,
    UserDept: <FiInfo className="inline-block text-lg text-pink-700 mr-2" />,
    TemporaryLocation: <FiBox className="inline-block text-lg text-pink-700 mr-2" />,
    PurchaseOrder: <FiTag className="inline-block text-lg text-pink-700 mr-2" />,
    SupplierName: <FiTool className="inline-block text-lg text-pink-700 mr-2" />
  };

  const handleCloseModal = () => {
    setShowQRCode(false);
    setSubmitted(false);
    setProductDetails({ ...initialProductDetails });
    setSelectedItem(null)
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-center">
      <Navbar/>
      <div className="bg-white bg-opacity-80 p-6 rounded-lg shadow-lg flex flex-col md:flex-row md:max-w-5xl">
        <div className="flex-grow w-full md:pr-8">
          <h1 className="text-3xl font-bold mb-6 text-center text-sky-800">QR Code Generator</h1>
          <form className="space-y-8" onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 ">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 ">
                  {icons['ItemCode']}
                  {'Item Code'}
                </label>
                <Select
                  options={itemCodes}
                  value={selectedItem}
                  onChange={handleItemChange}
                  placeholder="Select Item Code"
                  className="mt-0 p-2 block w-full  rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500 "
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {icons['ProductName']}
                  {'Product Name'}
                </label>
                <input
                  type="text"
                  name="ProductName"
                  value={productDetails.ProductName}
                  onChange={handleChange}
                  className={`mt-1 block w-full p-2 border ${productDetails.ProductName === '' && submitted ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500`}
                  
                  placeholder=" Product Name"
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {icons['ReceiptNo']}
                  {'Receipt No'}
                </label>
                <input
                  type="text"
                  name="ReceiptNo"
                  value={productDetails.ReceiptNo}
                  onChange={handleChange}
                  className={`mt-1 block w-full p-2 border ${productDetails.ReceiptNo === '' && submitted ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500`}
                  placeholder="Enter Receipt No"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {icons['ReceiptDate']}
                  {'Receipt Date'}
                </label>
                <input
                  type="date"
                  name="ReceiptDate"
                  value={productDetails.ReceiptDate}
                  onChange={handleChange}
                  className={`mt-1 block w-full p-2 border ${productDetails.ReceiptDate === '' && submitted ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500`}
                  placeholder="Select Receipt Date"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {icons['ItemDiscount']}
                  {'Item Discount'}
                </label>
                <input
                  type="number"
                  name="ItemDiscount"
                  value={productDetails.ItemDiscount}
                  onChange={handleChange}
                  className={`mt-1 block w-full p-2 border ${productDetails.ItemDiscount === '' && submitted ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500`}
                  placeholder="Enter Item Discount"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {icons['Quantity']}
                  {'Quantity'}
                </label>
                <input
                  type="number"
                  name="Quantity"
                  value={productDetails.Quantity}
                  onChange={handleChange}
                  className={`mt-1 block w-full p-2 border ${productDetails.Quantity === '' && submitted ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500`}
                  placeholder="Enter Quantity"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {icons['UserDept']}
                  {'User Dept'}
                </label>
                <input
                  type="text"
                  name="UserDept"
                  value={productDetails.UserDept}
                  onChange={handleChange}
                  className={`mt-1 block w-full p-2 border ${productDetails.UserDept === '' && submitted ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500`}
                  placeholder="Enter User Dept"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {icons['TemporaryLocation']}
                  {'Temporary Location'}
                </label>
                <input
                  type="text"
                  name="TemporaryLocation"
                  value={productDetails.TemporaryLocation}
                  onChange={handleChange}
                  className={`mt-1 block w-full p-2 border ${productDetails.TemporaryLocation === '' && submitted ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500`}
                  placeholder="Enter Temporary Location"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {icons['PurchaseOrder']}
                  {'Purchase Order'}
                </label>
                <input
                  type="text"
                  name="PurchaseOrder"
                  value={productDetails.PurchaseOrder}
                  onChange={handleChange}
                  className={`mt-1 block w-full p-2 border ${productDetails.PurchaseOrder === '' && submitted ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500`}
                  placeholder="Enter Purchase Order"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {icons['SupplierName']}
                  {'Supplier Name'}
                  </label>
                <input
                  type="text"
                  name="SupplierName"
                  value={productDetails.SupplierName}
                  onChange={handleChange}
                  className={`mt-1 block w-full p-2 border ${productDetails.SupplierName === '' && submitted ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500`}
                  placeholder="Enter Supplier Name"
                />
              </div>
            </div>
            <button
              type="submit"
              className="w-full  bg-sky-900 text-white p-3 rounded-md shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
            >
              Generate QR Code
            </button>
          </form>
        </div>
        {submitted && showQRCode && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col w-full md:max-w-3xl">
              <div className="flex justify-end mb-2">
                <button
                  className="text-sm text-gray-500 hover:text-gray-700 focus:outline-none"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
              </div>
              <div className="flex">
              <div className="flex flex-col w-1/2 pr-4">
  {['ItemCode', 'ProductName', 'ReceiptNo', 'ReceiptDate', 'ItemDiscount', 'Quantity', 'UserDept'].map((key) => (
    <div key={key} className="mb-2">
      <span className="font-medium text-gray-700">{key.replace(/([A-Z])/g, ' $1').trim()}:</span>
      <span className="ml-1">{productDetails[key]}</span>
    </div>
  ))}
</div>

                <div className="w-1/2 flex items-center justify-center">
                  <QRCode value={qrCodeValue} fgColor='purple' />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Product;

