import React, { useState } from "react";
import axios from "axios";
import Background1 from "../assests/img1.png";
import { useNavigate } from "react-router-dom";
import config from "../config";

const Login = () => {
  const [loginData, setLoginData] = useState({
    loginIdentifier: "",
    password: "",
  });
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState(null);
  const [color, setColor] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value.trim() });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    try {
      const response = await axios.post(
        `${config.apiUrl}/users/login`,
        loginData
      );
      if (response.data.status === 200) {
        setAlertMessage("Login Successful!");
        setColor(true);
        setTimeout(() => {
          setAlertMessage(null);
          localStorage.setItem("user", JSON.stringify(response.data.data));
          sessionStorage.setItem("authentication", true);

          const userRole = response.data.data.user.user_role;
          console.log(userRole);

          navigate("/product");
          setIsButtonDisabled(false);
        }, 2000);
      }
    } catch (error) {
      console.error("Login failed:", error);
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data.message;
        if (errorMessage === "Invalid username or password") {
          setAlertMessage("Invalid username or password");
        } else if (errorMessage === "Invalid username") {
          setAlertMessage("Invalid username or password");
        } else if (errorMessage === "Invalid password") {
          setAlertMessage("Invalid password.");
        } else {
          setAlertMessage("Invalid username or password");
        }
        setTimeout(() => {
          setAlertMessage(null);
          setIsButtonDisabled(false);
        }, 2000);
      } else {
        console.error("An unexpected error occurred:", error);
        setAlertMessage("An unexpected error occurred. Please try again.");
        setTimeout(() => {
          setAlertMessage(null);
          setIsButtonDisabled(false);
        }, 2000);
      }
    }
  };

  const alertStyle = {
    position: "fixed",
    bottom: "48px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: color ? "blue" : "red",
    color: "white",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(255, 255, 255, 1)",
    zIndex: "9999",
    display: alertMessage ? "block" : "none",
    opacity: alertMessage ? 1 : 0,
    transition: "opacity 0.5s ease-in-out",
  };

  return (
    <div className="flex h-screen bg-slate-800 justify-center items-center">
        <h1 className="absolute top-0 left-1/2 transform -translate-x-1/2  text-gray-50 mt-4 text-3xl">
            STORE MANAGEMENT
          </h1>
      <div className="flex bg-white w-3/4 h-3/4 rounded-lg overflow-hidden shadow-lg p-12">
        <div
          className="flex-1"
          style={{
            backgroundImage: `url(${Background1})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}
        ></div>

        <div className="flex-1 flex items-center justify-center relative">
        

          <div
            className="w-full max-w-md p-8 bg-gray-800 border border-slate-800 "
            style={{ boxShadow: "0 0 10px rgba(0, 0, 50, 1)" }}
          >
            <div className="text-center text-gray-50 m-3">
              <h2 className="text-3xl mb-0">
                <b>User Login</b>
              </h2>
              <p className="text-xl">Welcome back! Please enter your details</p>
            </div>
            <div style={alertStyle}>{alertMessage}</div>

            <form onSubmit={handleLogin} className="text-center">
              <div className="mb-4">
                <label htmlFor="loginIdentifier">
                  <b className="text-lg text-gray-50">
                    User Name / Mobile Number
                  </b>
                </label>
                <br />
                <input
                  type="text"
                  id="loginIdentifier"
                  name="loginIdentifier"
                  placeholder="User Name or Mobile Number"
                  className="p-3 rounded w-full bg-gray-50 border border-blue-900"
                  value={loginData.loginIdentifier}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="password" className="form-label">
                  <b className="text-lg text-gray-50">Password</b>
                </label>
                <br />
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  className="p-3 rounded w-full bg-gray-50 border border-blue-900"
                  value={loginData.password}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn bg-slate-900 text-white text-lg p-2 rounded"
                  style={{ boxShadow: "0 0 10px rgba(255, 255, 255, 1)" }}
                  disabled={isButtonDisabled}
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;